import { useEffect, useState } from 'react';
import styles from './GreatestMoveBlock.module.css';
import { useSelector, useDispatch } from 'react-redux';
import API from '@api';
import { setMove } from '@store/bestMoveSlice';

const GreatestMoveBlock = () => {
  const dispatch = useDispatch();

  const currentDesk = useSelector((state) => state.currentDesk.desk);
  const [isLoading, setIsLoading] = useState(false);
  const [bestMoveResponse, setBestMoveResponse] = useState();

  useEffect(() => {
    setIsLoading(true);
    API.GetBestMove({ fen: currentDesk.fen }).then((result) => {
      setBestMoveResponse(result.data.bestmove);
      setIsLoading(false);
    });
  }, [currentDesk]);

  const setBestMove = () => {
    if (bestMoveResponse) {
      dispatch(setMove(bestMoveResponse));
    }
  };

  return (
    <div className={styles.move_block}>
      {isLoading ? (
        <div className={styles.loader}>
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      ) : (
        <button className={styles.move_block__button} onClick={setBestMove}>
          <span className={styles.move_block__span}>Лучший ход</span>
          <div className={styles.move_block__arrow_block}>
            <span className={styles.move_block__span}>
              {bestMoveResponse?.substring(0, 2)}
            </span>
            <div className={styles.move_block__arrow}></div>
            <span className={styles.move_block__span}>
              {bestMoveResponse?.substring(2)}
            </span>
          </div>
        </button>
      )}
    </div>
  );
};

export default GreatestMoveBlock;
