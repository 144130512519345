import styles from './HistoryBlock.module.css';
import HistoryItem from '@components/HistoryItem';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const HistoryBlock = () => {
  const historyList = useSelector((state) => state.history.historyList);

  return (
    <div className={styles.history}>
      <span className={styles.history__title}>История ходов</span>
      <ul className={styles.history__list}>
        {historyList.map((item, index, array) => {
          const reverseIndex = array.length - index - 1;
          return (
            <HistoryItem
              team={reverseIndex % 2 === 0 ? 'white' : 'black'}
              key={index}
              move={item.move}
            />
          );
        })}
      </ul>
    </div>
  );
};

HistoryBlock.propTypes = {
  list: PropTypes.array,
};

export default HistoryBlock;
