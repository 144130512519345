import styles from './TableButton.module.css';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import API from '@api';
import { useDispatch, useSelector } from 'react-redux';
import { removeDesk } from '@store/desksSlice';
import { setDesk } from '@store/currentDesk';
import { Modal } from '@mui/material';
import Button from '../Button';

const TableButton = ({ tableIndex, deskId, active, chooseDesk }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const desksList = useSelector((state) => state.desks.desks);
  // const currentDesk = useSelector((state) => state.currentDesk.desk);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleContextMenu = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  let timeoutId = null;

  function handleMouseDown(event) {
    timeoutId = setTimeout(function () {
      setAnchorEl(event.currentTarget);
    }, 1000);
  }

  function handleMouseUp() {
    clearTimeout(timeoutId);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setOpenModal(false);
  };

  const handleDelete = () => {
    API.DeleteDesk({ id: deskId }).then(() => {
      dispatch(removeDesk(deskId));
      if (desksList.length <= 1) {
        dispatch(
          setDesk({
            id: 0,
            fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
            deskName: `Новый стол`,
          }),
        );
      } else {
        dispatch(setDesk(desksList[0]));
      }
      setOpenModal(false);
    });
  };

  return (
    <>
      <button
        className={
          styles.table_button +
          ' ' +
          (active ? styles.table_button__active : '')
        }
        onClick={() => {
          chooseDesk(deskId);
        }}
        onContextMenu={handleContextMenu}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        <span>Стол</span> {tableIndex}
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{ borderRadius: '8px' }}
      >
        <button
          className={styles.popup__button}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          удалить стол {tableIndex}
        </button>
      </Popover>

      <Modal
        open={openModal}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(6px)',
            WebkitBackdropFilter: 'blur(6px)',
          },
        }}
        onClose={handleClose}
      >
        <div className={styles.modal__content}>
          <h2 className={styles.modal__title}>
            Вы уверены, что хотите удалить {tableIndex} ?
          </h2>
          <div className={styles.modal__buttons}>
            <Button text={'Удалить'} handle={handleDelete} option={'cancel'} />
            <Button text={'Отмена'} handle={handleClose} />
          </div>
        </div>
      </Modal>
    </>
  );
};

TableButton.propTypes = {
  tableIndex: PropTypes.string,
  active: PropTypes.bool,
  chooseDesk: PropTypes.func,
  deskId: PropTypes.number,
};

export default TableButton;
