import styles from './App.module.css';
import LeftInfoDesk from '@containers/LeftInfoDesk';
import RightTableSelect from '@containers/RightTableSelect';
import ChessContainer from '@containers/ChessContainer';
import { useEffect, useState } from 'react';
import API from '@api';
import { useDispatch, useSelector } from 'react-redux';
import { setDesks } from '@store/desksSlice';
import { setDesk } from '@store/currentDesk';
import CustomLeftDesk from '../CustomLeftDesk';
import CustomRightDesk from '../CustomRightDesk';
import LoginPage from '../LoginPage';

const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useState(false);

  const customDesk = useSelector((state) => state.customDesk.desk);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setAuth(true);
    }
  }, []);

  useEffect(() => {
    API.GetAllBoards().then((data) => {
      dispatch(setDesks(data.data));
      dispatch(setDesk(data.data.length > 0 ? data.data[0] : {}));
      setIsLoading(false);
    });
  }, [dispatch]);

  return (
    <>
      {auth ? (
        <div className={styles.app}>
          {isLoading ? (
            <h1>Loading...</h1>
          ) : (
            <div className={styles.app__wrapper}>
              {customDesk.boardNumber ? <CustomLeftDesk /> : <LeftInfoDesk />}
              <ChessContainer />
              {customDesk.boardNumber ? (
                <CustomRightDesk />
              ) : (
                <RightTableSelect />
              )}
            </div>
          )}
        </div>
      ) : (
        <LoginPage />
      )}
    </>
  );
};

export default App;
