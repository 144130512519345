import {createSlice} from '@reduxjs/toolkit';

const historySlice = createSlice({
    name: 'history',
    initialState: {
        historyList: []
    },
    reducers: {
        setHistoryList(state, action) {
            state.historyList = action.payload
        },
        setHistoryItem(state, action) {
            const {deskId, move} = action.payload;

            state.historyList.unshift({desk_id: deskId, move: move});
        },
        // eslint-disable-next-line no-unused-vars
        undoMove(state, action) {
            state.historyList.shift()
        }
    }
});

export const {setHistoryList, setHistoryItem, undoMove} = historySlice.actions;

export default historySlice.reducer;