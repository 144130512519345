import styles from './MovesBlock.module.css';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const MovesBlock = () => {
  const historyList = useSelector((state) => state.history.historyList);
  const currentDesk = useSelector((state) => state.currentDesk.desk);

  return (
    <div className={styles.moves}>
      <div className={styles.moves__wrapper}>
        <span className={styles.moves__span}>{historyList.length + 1} ход</span>
        <span
          className={styles.moves__span + ' ' + styles.moves__span_overlined}
        >
          {currentDesk.fen?.split(' ')[1] === 'w' ? 'Ход белых' : 'Ход черных'}
        </span>
      </div>
    </div>
  );
};

MovesBlock.propTypes = {
  list: PropTypes.array,
};

export default MovesBlock;
