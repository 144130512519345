import {configureStore} from '@reduxjs/toolkit';

import desksReducer from './desksSlice';
import currentDeskReducer from './currentDesk';
import historyReduser from './historySlice';
import customDeskReduser from './customDeskSlice';
import selectedPiece from './selectedPiece';
import selectedCustomSquare from './selectedCustomSquare';
import bestMoveReduser from './bestMoveSlice';

export default configureStore({
    reducer: {
        desks: desksReducer,
        currentDesk: currentDeskReducer,
        history: historyReduser,
        customDesk: customDeskReduser,
        selectedPiece: selectedPiece,
        selectedCustomSquare: selectedCustomSquare,
        bestMove: bestMoveReduser
    }
})