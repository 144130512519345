import styles from './MyChessBoard.module.css';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from '@api';
import { setDeskFen } from '@store/currentDesk';
import { setHistoryItem } from '@store/historySlice';
import { setPiece } from '@store/selectedPiece';
import { setPiecesPosition, deletePiece } from '@store/customDeskSlice';
import { setCustomSquare } from '@store/selectedCustomSquare';
import { Modal } from '@mui/material';
import ChoosePiece from '../ChoosePiece';

import { Chess } from 'chess.js';
import { Chessboard } from 'react-chessboard';

import { editDesk } from '@store/desksSlice';

import wP from '@images/Chess/wP.svg';
import wN from '@images/Chess/wN.svg';
import wB from '@images/Chess/wB.svg';
import wK from '@images/Chess/wK.svg';
import wQ from '@images/Chess/wQ.svg';
import wR from '@images/Chess/wR.svg';
import bB from '@images/Chess/bB.svg';
import bK from '@images/Chess/bK.svg';
import bN from '@images/Chess/bN.svg';
import bP from '@images/Chess/bP.svg';
import bQ from '@images/Chess/bQ.svg';
import bR from '@images/Chess/bR.svg';

const MyChessBoard = () => {
  const dispatch = useDispatch();

  const currentDesk = useSelector((state) => state.currentDesk.desk);
  const customDesk = useSelector((state) => state.customDesk.desk);
  const selectedCustomPiece = useSelector((state) => state.selectedPiece.piece);
  const customChessPosition = useSelector(
    (state) => state.customDesk.desk.position,
  );
  const selectedCustomSquare = useSelector(
    (state) => state.selectedCustomSquare.square,
  );
  const bestMove = useSelector((state) => state.bestMove.bestMove);

  const [game, setGame] = useState(new Chess());
  const [moveFrom, setMoveFrom] = useState('');
  const [rightClickedSquares, setRightClickedSquares] = useState({});
  const [lastMoveSquares, setLastMoveSquares] = useState({});
  const [optionSquares, setOptionSquares] = useState({});
  const [bestMoveSquares, setBestMoveSquares] = useState({});
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [hoveredSquare, setHoveredSquare] = useState({});
  const [boardLoading, setBoardLoading] = useState(true);
  const [selectedPiece, setSelectedPiace] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [bufferSquare, setBufferSquare] = useState();

  const chessboardRef = useRef(null);

  const pieces = [
    { name: 'wP', link: wP },
    { name: 'wN', link: wN },
    { name: 'wB', link: wB },
    { name: 'wR', link: wR },
    { name: 'wQ', link: wQ },
    { name: 'wK', link: wK },
    { name: 'bP', link: bP },
    { name: 'bN', link: bN },
    { name: 'bB', link: bB },
    { name: 'bR', link: bR },
    { name: 'bQ', link: bQ },
    { name: 'bK', link: bK },
  ];

  const handleClose = () => {
    setOpenModal(false);
  };

  const createCustomPieces = () => {
    const returnPieces = {};
    pieces.map((p) => {
      returnPieces[p.name] = ({ squareWidth }) => (
        <div
          style={{
            width: squareWidth,
            height: squareWidth,
            backgroundImage: `url("${p.link}")`,
            backgroundSize: '100%',
          }}
        />
      );
      return null;
    });
    return returnPieces;
  };

  const updateDimensions = () => {
    const { width } = chessboardRef.current.getBoundingClientRect();
    setContainerDimensions({ width, height: width });
    setBoardLoading(false);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    setGame(new Chess(currentDesk.fen));
  }, [currentDesk]);

  useEffect(() => {
    setLastMoveSquares({});
    setBestMoveSquares({});
  }, [currentDesk.id]);

  useEffect(() => {
    updateDimensions();
  }, [customDesk.boardNumber]);

  useEffect(() => {
    colorizeBestMove(bestMove);
  }, [bestMove]);

  function getMoveOptions(square) {
    const moves = game.moves({
      square,
      verbose: true,
    });
    if (moves.length === 0) {
      return false;
    }

    const newSquares = {};
    moves.map((move) => {
      newSquares[move.to] = {
        background:
          game.get(move.to) &&
          game.get(move.to).color !== game.get(square).color
            ? 'radial-gradient(circle, rgba(0,0,0,.1) 85%, transparent 85%)'
            : 'radial-gradient(circle, rgba(0,0,0,.1) 25%, transparent 25%)',
        borderRadius: '50%',
      };
      return move;
    });
    newSquares[square] = {
      background: '#34364C',
    };

    setOptionSquares(newSquares);
    return true;
  }

  function handleOpenModal(square) {
    setOpenModal(true);
    setBufferSquare(square);
  }

  function onSquareClick(square) {
    setRightClickedSquares({});

    function resetFirstMove(square) {
      const hasOptions = getMoveOptions(square);
      if (hasOptions) setMoveFrom(square);
    }

    // from square
    if (!moveFrom) {
      resetFirstMove(square);
      return;
    }

    // attempt to make move
    const gameCopy = game;

    if (Object.keys(optionSquares).includes(square) && square !== moveFrom) {
      if (
        selectedPiece.substring(1) === 'P' &&
        (square.endsWith('8') || square.endsWith('1'))
      ) {
        handleOpenModal(square);
        return;
      } else {
        const move = gameCopy.move({
          from: moveFrom,
          to: square,
        });
        colorizeLastMove(moveFrom, square);
        if (gameCopy.isCheckmate()) {
          alert(gameCopy.turn() === 'b' ? 'Белые выиграли' : 'Черные выиграли');
        }

        if (!move) {
          resetFirstMove(square);
          return;
        }

        sendMove(moveFrom, square);
      }
      dispatch(editDesk({ id: currentDesk.id, fen: gameCopy.fen() }));
      dispatch(setDeskFen(gameCopy.fen()));
      dispatch(
        setHistoryItem({
          desk_id: currentDesk.id,
          move: `${moveFrom}${square}`,
        }),
      );
      setGame(gameCopy);
    }

    setMoveFrom('');
    setOptionSquares({});
  }

  function chooseModalPiece(piece) {
    const gameCopy = game;

    function resetFirstMove(square) {
      const hasOptions = getMoveOptions(square);
      if (hasOptions) setMoveFrom(square);
    }

    const newPieceType = piece;
    if (newPieceType) {
      const move = gameCopy.move({
        from: moveFrom,
        to: bufferSquare,
        promotion: newPieceType,
      });
      colorizeLastMove(moveFrom, bufferSquare);
      if (gameCopy.isCheckmate()) {
        alert(gameCopy.turn() === 'b' ? 'Белые выиграли' : 'Черные выиграли');
      }

      if (!move) {
        resetFirstMove(bufferSquare);
        return;
      }

      sendMove(moveFrom, bufferSquare, newPieceType);

      dispatch(editDesk({ id: currentDesk.id, fen: gameCopy.fen() }));
      dispatch(setDeskFen(gameCopy.fen()));
      dispatch(
        setHistoryItem({
          desk_id: currentDesk.id,
          move: `${moveFrom}${bufferSquare}`,
        }),
      );
      setOptionSquares({});
      setMoveFrom('');
      setGame(gameCopy);
      setOpenModal(false);
    }
  }

  function colorizeLastMove(from, to) {
    const color = 'rgba(252, 250, 177, 0.5)';
    setLastMoveSquares({
      [from]: { backgroundColor: color },
      [to]: { backgroundColor: color },
    });
  }

  function colorizeBestMove(move) {
    const color = '#8aff888a';
    setBestMoveSquares({
      [move.substring(0, 2)]: {
        background: `${color}`,
        boxSizing: 'border-box',
      },
      [move.substring(2)]: {
        background: `${color}`,
        boxSizing: 'border-box',
      },
    });
  }

  function onSquareRightClick(square) {
    const colour = 'rgba(0, 0, 255, 0.4)';
    setRightClickedSquares({
      ...rightClickedSquares,
      [square]:
        rightClickedSquares[square] &&
        rightClickedSquares[square].backgroundColor === colour
          ? undefined
          : { backgroundColor: colour },
    });
  }

  function handleMouseOverSquare(square) {
    if (square !== moveFrom) {
      const border = '1px solid #000000';
      setHoveredSquare({
        [square]:
          hoveredSquare[square] && hoveredSquare[square].border === border
            ? undefined
            : { border: border, boxSizing: 'border-box' },
      });
    }
  }

  function handleMouseOutSquare() {
    setHoveredSquare('');
  }

  function sendMove(from, to, peace) {
    const moveData = {
      deskId: currentDesk.id,
      move: `${from}${to}`,
      promotion: peace ? peace : 'q',
    };
    API.PostNewMove(moveData);
  }

  function onCustomSquareClick(square) {
    dispatch(setCustomSquare(square));
    if (selectedCustomPiece.chessValue) {
      dispatch(
        setPiecesPosition({
          square: square,
          piace: selectedCustomPiece.chessValue,
        }),
      );
      dispatch(setPiece(undefined));
      dispatch(setCustomSquare(''));
    }
    if (selectedPiece) {
      dispatch(deletePiece(selectedCustomSquare));
      dispatch(
        setPiecesPosition({
          square: square,
          piace: selectedPiece,
        }),
      );
      setSelectedPiace();
      dispatch(setCustomSquare(''));
    }
  }

  function handlePieceClick(piece) {
    setSelectedPiace(piece);
  }

  return (
    <>
      <div className={styles.chess__container}>
        <div className={styles.chess__number_block}>
          <span className={styles.chess__number}>8</span>
          <span className={styles.chess__number}>7</span>
          <span className={styles.chess__number}>6</span>
          <span className={styles.chess__number}>5</span>
          <span className={styles.chess__number}>4</span>
          <span className={styles.chess__number}>3</span>
          <span className={styles.chess__number}>2</span>
          <span className={styles.chess__number}>1</span>
        </div>
        <div className={styles.chess__board} ref={chessboardRef}>
          {boardLoading ? (
            ''
          ) : !customDesk.boardNumber ? (
            <Chessboard
              customPieces={createCustomPieces()}
              id="ClickToMove"
              boardWidth={containerDimensions.width}
              animationDuration={200}
              arePiecesDraggable={false}
              position={game.fen()}
              onSquareClick={onSquareClick}
              onSquareRightClick={onSquareRightClick}
              onMouseOverSquare={handleMouseOverSquare}
              onMouseOutSquare={handleMouseOutSquare}
              onPieceClick={handlePieceClick}
              customSquareStyles={{
                ...lastMoveSquares,
                ...bestMoveSquares,
                ...optionSquares,
                ...rightClickedSquares,
              }}
              customDarkSquareStyle={{
                backgroundColor: '#B7C0D8',
                boxSizing: 'border-box',
              }}
              customLightSquareStyle={{
                backgroundColor: '#E8EDF9',
                boxSizing: 'border-box',
              }}
              showBoardNotation={false}
            />
          ) : (
            <Chessboard
              customPieces={createCustomPieces()}
              boardWidth={containerDimensions.width}
              arePiecesDraggable={false}
              position={
                Object.keys(customChessPosition) ? customChessPosition : ''
              }
              onSquareClick={onCustomSquareClick}
              onMouseOverSquare={handleMouseOverSquare}
              onMouseOutSquare={handleMouseOutSquare}
              onPieceClick={handlePieceClick}
              customSquareStyles={{
                ...(customChessPosition[selectedCustomSquare] === selectedPiece
                  ? {
                      [selectedCustomSquare]: { backgroundColor: '#34364C' },
                    }
                  : ''),
              }}
              customDarkSquareStyle={{
                backgroundColor: '#B7C0D8',
                boxSizing: 'border-box',
              }}
              customLightSquareStyle={{
                backgroundColor: '#E8EDF9',
                boxSizing: 'border-box',
              }}
              showBoardNotation={false}
              sparePieces={false}
            />
          )}
        </div>
        <div className={styles.chess__letter_block}>
          <span className={styles.chess__letter}>a</span>
          <span className={styles.chess__letter}>b</span>
          <span className={styles.chess__letter}>c</span>
          <span className={styles.chess__letter}>d</span>
          <span className={styles.chess__letter}>e</span>
          <span className={styles.chess__letter}>f</span>
          <span className={styles.chess__letter}>g</span>
          <span className={styles.chess__letter}>h</span>
        </div>
      </div>
      <Modal
        open={openModal}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(6px)',
            WebkitBackdropFilter: 'blur(6px)',
          },
        }}
        onClose={handleClose}
      >
        <ChoosePiece
          color={selectedPiece?.startsWith('w') ? 'white' : 'black'}
          handle={chooseModalPiece}
        />
      </Modal>
    </>
  );
};

export default MyChessBoard;
